<template>
	<div class="o-wrap">
		<div class="tit">
			<el-steps :active="active" finish-status="success">
				<el-step title="基本信息" />
				<el-step title="企业认证" />
				<el-step title="身份认证" />
			</el-steps>
		</div>
		<div class="content">
			<router-view @eventNext="handleNext" @eventSubmit="handleSubmit"  v-loading.fullscreen.lock="loading" />
		</div>
	</div>
</template>

<script>
	import Axios from 'axios'
	export default {
		data() {
			return {
				active: this.$route.name.charAt(this.$route.name.length - 1) - 1,
				form: {
					user_id: this.$store.state.USER_ID
				},
				loading:false,
			}
		},
		created() {
			Axios.post('/getcompany',{user_id:this.$store.state.SELLER_ID}).then(data => {
				if(data.data.data  != null){
					this.form = data.data.data
				}
			})
		},
		methods: {
			handleNext(data) {
				this.active++
				Object.assign(this.form, data)
				this.$router.push({path:`/authen/s${this.active+1}`,query:{queryForm:this.form}});
			},
			handleSubmit(data) {
				Object.assign(this.form, data)
				this.loading = true
				Axios.post(this.$store.state.BASE_URL+'/setcompany', this.form).then(data => {
					let datas = data.data
					if (datas.code == 41004) {
						this.loading = false
						this.$confirm('正在审核中，不支持修改', '提示', {
							type: 'warning',
							showCancelButton: false
						}).then(() => {
							this.$router.push('/')
						})
						return
					}else if(datas.code== 200){
						this.loading = false
						this.$confirm('提交成功，请等待工作审核（审核时间1-3个工作日）', '提示', {
							type: 'success',
							showCancelButton: false
						}).then(() => {
							location.href = this.$store.state.USER_URL
						})
						
					}else{
						this.loading = false
						this.$confirm(datas.msg, '提示', {
							type: 'warning',
							showCancelButton: false
						}).then(() => {
							this.$router.push('/')
						})
					}
					
				})
			},
		}
	}
</script>

<style scoped>
	.tit {
		max-width: 1000px;
		margin: 30px auto;
	}

	.content {
		max-width: 1000px;
		margin: 0 auto;
	}

	.form {
		max-width: 900px;
	}

	.form>>>.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.form>>>.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.form>>>.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.form>>>.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.form>>>.tips {
		font-size: 14px;
		color: #999999;
		line-height: 26px;
		margin: 20px 0 60px;
	}
</style>
